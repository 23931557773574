@-webkit-keyframes pulse1 {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(48, 119, 157, 0.6);
    box-shadow: 0 0 0 0 rgba(48, 119, 157, 0.6);
  }
  70% {
    -webkit-box-shadow: 0 0 0 15px rgba(48, 119, 157, 0);
    box-shadow: 0 0 0 15px rgba(48, 119, 157, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(48, 119, 157, 0);
    box-shadow: 0 0 0 0 rgba(48, 119, 157, 0);
  }
}

@keyframes pulse1 {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(48, 119, 157, 0.6);
    box-shadow: 0 0 0 0 rgba(48, 119, 157, 0.6);
  }
  70% {
    -webkit-box-shadow: 0 0 0 15px rgba(48, 119, 157, 0);
    box-shadow: 0 0 0 15px rgba(48, 119, 157, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(48, 119, 157, 0);
    box-shadow: 0 0 0 0 rgba(48, 119, 157, 0);
  }
}

@-webkit-keyframes pulse2 {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(48, 119, 157, 0.6);
    box-shadow: 0 0 0 0 rgba(48, 119, 157, 0.6);
  }
  70% {
    -webkit-box-shadow: 0 0 0 15px rgba(48, 119, 157, 0);
    box-shadow: 0 0 0 15px rgba(48, 119, 157, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(48, 119, 157, 0);
    box-shadow: 0 0 0 0 rgba(48, 119, 157, 0);
  }
}

@keyframes pulse2 {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(48, 119, 157, 0.6);
    box-shadow: 0 0 0 0 rgba(48, 119, 157, 0.6);
  }
  70% {
    -webkit-box-shadow: 0 0 0 15px rgba(48, 119, 157, 0);
    box-shadow: 0 0 0 15px rgba(48, 119, 157, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(48, 119, 157, 0);
    box-shadow: 0 0 0 0 rgba(48, 119, 157, 0);
  }
}

/* Pulse animation classes */
.ltn__animation-pulse1 button {
  -webkit-animation: pulse1 1s infinite;
  animation: pulse1 1s infinite;
}

.ltn__animation-pulse2 button {
  -webkit-animation: pulse2 1s infinite;
  animation: pulse2 1s infinite;
}
