/* Global Styles */
body, html, #root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

/* Poppins Font Faces */
@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Thin.ttf') format('truetype');
  font-weight: 100; /* Thin */
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-ThinItalic.ttf') format('truetype');
  font-weight: 100; /* Thin */
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-ExtraLight.ttf') format('truetype');
  font-weight: 200; /* ExtraLight */
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200; /* ExtraLight */
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Light.ttf') format('truetype');
  font-weight: 300; /* Light */
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-LightItalic.ttf') format('truetype');
  font-weight: 300; /* Light */
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: 400; /* Regular */
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Italic.ttf') format('truetype');
  font-weight: 400; /* Italic */
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500; /* Medium */
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-MediumItalic.ttf') format('truetype');
  font-weight: 500; /* Medium */
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600; /* SemiBold */
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600; /* SemiBold */
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: 700; /* Bold */
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-BoldItalic.ttf') format('truetype');
  font-weight: 700; /* Bold */
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-ExtraBold.ttf') format('truetype');
  font-weight: 800; /* ExtraBold */
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800; /* ExtraBold */
  font-style: italic;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-Black.ttf') format('truetype');
  font-weight: 900; /* Black */
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/Poppins-BlackItalic.ttf') format('truetype');
  font-weight: 900; /* Black */
  font-style: italic;
}

/* Selection Styles */
::selection {
  background-color: #30779d;
  color: #ffffff; 
}

::-moz-selection {
  background-color: #30779d;
  color: #ffffff; 
}



